// @flow strict
import React from 'react';
import { graphql } from 'gatsby';
import Portfolio from '../components/Portfolio';
import Navbar from '../components/Navbar';
import Layout from '../components/Layout';
import Page from '../components/Page';
import Pagination from '../components/Pagination';
import { useSiteMetadata } from '../hooks';
import type { PageContext, AllMarkdownRemark } from '../types';

type Props = {
  data: AllMarkdownRemark,
  pageContext: PageContext
};

const WorkTemplate = ({ data, pageContext }: Props) => {
  const { title, subtitle } = useSiteMetadata();
  const { edges } = data.allMarkdownRemark;
  const {
    currentPage,
    prevPagePath,
    nextPagePath,
    hasPrevPage,
    hasNextPage
  } = pageContext;

  return (
    <Layout title={`Work - ${title}`} description={subtitle}>
      <Navbar/>
      <Page title={'Work'}>
        <Portfolio edges={edges}/>
        <Pagination
          prevPagePath={prevPagePath}
          nextPagePath={nextPagePath}
          hasPrevPage={hasPrevPage}
          hasNextPage={hasNextPage}
        />
      </Page>
    </Layout>
  );
};


export const query = graphql`
  query WorkTemplate {
    allMarkdownRemark(
        filter: { frontmatter: { template: { eq: "page" }, draft: { ne: true } } },
        sort: { order: DESC, fields: [frontmatter___date] }
      ){
      edges {
        node {
          fields {
            slug
            categorySlug
          }
          frontmatter {
            title
            desc
            date
            category
            description
            img {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default WorkTemplate;