// @flow strict
import React from 'react';
import { useSiteMetadata } from '../../hooks';
import Grid from './Grid';
import type { Edges } from '../../types';
import { getIcon } from '../../utils';
import Icon from '../Icon';
import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import styles from './Portfolio.module.scss';
import { useTagsList } from '../../hooks';

type Props = {
  edges: Edges
};

const Portfolio = ({ edges }: Props) => {
  const { portfolio } = useSiteMetadata();
  const tags = useTagsList();

  return (
    <div className={styles['portfolio']}>
      {'If this looks different, it\'s because I\'m completely rebuilding it right now! There are only a few projects with incomplete descriptions. Please DM me if interested.'}
      <ul className={styles['portfolio__list']}>
        <li className={styles['portfolio__list-link']} key={'work'}>
          <Link className={styles['portfolio__list-link-item']} to={`/work/`}>
            <div>All</div>
          </Link>
        </li>
        {tags.map((tag) => (
          <li className={styles['portfolio__list-link']} key={tag.fieldValue}>
            <Link className={styles['portfolio__list-link-item']} to={`/tag/${kebabCase(tag.fieldValue)}/`}>
              <div>{tag.fieldValue}</div>
            </Link>
          </li>
        ))}
      </ul>
      <Grid portfolio={portfolio} edges={edges} />
    </div>
  );
};

export default Portfolio;