// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import type { Edges } from '../../types';
import styles from './Grid.module.scss';

type Props = {
  edges: Edges
};

const Grid = ({ edges }: Props) => (
  <div className={styles['grid']}>
    {edges.map((edge) => (
      <div className={styles['grid__item']} key={edge.node.fields.slug}>
        <Link className={styles['grid__item-cover']} to={edge.node.fields.slug}>
          <Img className={styles['grid__item-cover-img']} fluid={edge.node.frontmatter.img.childImageSharp.fluid} />
          <p className={styles['grid__item-cover-title']}>
            {edge.node.frontmatter.title}
          </p>
          <figcaption className={styles['grid__item-cover-desc']}>
            {edge.node.frontmatter.desc}
          </figcaption>
        </Link>
      </div>
    ))}
  </div>
);

export default Grid;